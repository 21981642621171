<template>
  <page-view title="帮助中心">
    <Row :gutter="20">
      <Col :span="6">
      <SideBar></SideBar>
      </Col>
      <Col :span="18">
      <router-view></router-view>
      </Col>
    </Row>
  </page-view>
</template>

<script>
import SideBar from './components/SideBar'
export default {
  components: {
    SideBar
  },
  data () {
    return {
      documentList: []
    }
  },
  created () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.searchform {
    padding: 30px 20px;
    background: #ffffff;
}
.art-list {
    .art-item {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #e9ecef;
    }
    .title {
        font-size: 18px;
        color: #000;
    }
    .desc {
        color: #999999;
    }
}
</style>
