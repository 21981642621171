<template>
  <div>

    <div class="searchform">
      <Input placeholder="请输入搜索内容" style="width:400px;" size="large" search enter-button="搜索" @on-search="onSearch"></Input>
    </div>

    <Card style="margin-top: 20px;">
      <div class="detail">
        <div class="title">{{get(detail,'docName')}}</div>
        <p class="datetime">{{get(detail,'createTime')}}</p>
        <div class="detail-con" v-html="get(detail,'docContent')">
        </div>
      </div>
    </Card>

  </div>
</template>

<script>
import { getDocumentInfo } from '@/api/packing'
export default {
  components: {
  },
  data () {
    return {
      detail: {}
    }
  },
  created () {
    this.getDocumentInfo()
  },
  watch: {
    $route: {
      deep: true,
      handler: function () {
        this.getDocumentInfo()
      }
    }
  },
  methods: {
    getDocumentInfo () {
      const { id } = this.$route.params
      getDocumentInfo(id).then(res => {
        if (res.code === 0) {
          this.detail = res.data
        } else {
          this.$Message.error(res.msg)
        }
      })
    },
    onSearch (value) {
      this.$router.push({ name: 'help', query: { q: value } })
    }
  }
}
</script>

<style lang="scss" scoped>
.searchform {
    padding: 30px 20px;
    background: #ffffff;
}
.detail {
    padding: 20px;
    .title {
        font-size: 22px;
    }

    .detail-con {
        color: #666;
        line-height: 30px;
        margin-top: 20px;
        /deep/ img {
            max-width: 100%;
        }
    }

    .datetime {
        color: #999999;
    }
}
</style>
