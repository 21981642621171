<template>
  <div>
    <div class="searchform">
      <Input placeholder="请输入搜索内容" style="width:400px;" size="large" search enter-button="搜索" @on-search="onSearch"></Input>
    </div>
    <Card style="margin-top: 20px;">
      <div class="art-list">
        <div v-for="item in documentList" :key="item.id" class="art-item">
          <div class="title">
            <router-link :to="{name:'help.detail',params:{id:item.id}}">{{item.docName}}</router-link>
          </div>
          <div class="desc">
            {{strippedContent(item.docContent) | truncate(200)}}
          </div>
        </div>
      </div>

      <div v-if="total > documentList.length" class="text-center">
        <Button :loading="loading" @click="loadNext">加载更多...
        </Button>
      </div>
    </Card>
  </div>
</template>

<script>
import { getDocument } from '@/api/packing'
export default {

  data () {
    return {
      documentList: [],
      pageIndex: 1,
      loading: false,
      total: 0
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const { q } = this.$route.query
      const params = {
        pageSize: 5,
        pageNum: this.pageIndex
      }
      if (q) {
        // params.docName = q
        params.docContent = q
      }
      return getDocument(params).then(res => {
        if (res.code === 0) {
          this.documentList = this.documentList.concat(res.rows)
          this.total = res.total
        }
      })
    },
    async loadNext () {
      this.pageIndex++
      this.loading = true
      await this.getData()
      this.loading = false
    },
    onSearch (value) {
      this.pageIndex = 1
      this.$router.push({ name: 'help', query: { q: value } })
      this.getData()
    },
    strippedContent (content) {
      const regex = /(<([^>]+)>)/gi
      return content.replace(regex, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.searchform {
    padding: 30px 20px;
    background: #ffffff;
}
.art-list {
    .art-item {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #e9ecef;
    }
    .title {
        font-size: 18px;
        color: #000;
    }
    .desc {
        color: #999999;
        padding: 10px 0px;
    }
}
</style>
