<template>
  <Affix>
    <Card title="分类目录" class="stick" :padding="0">
      <Menu v-if="loading === cateList.length" :active-name="activeName+''" :open-names="openNames" width="auto">
        <Submenu v-for="item in cateList" :key="item.id" :name="item.docName">
          <template slot="title">
            {{item.docName}}
          </template>
          <MenuItem v-for="child in item.child" :key="child.id" :name="child.id+''"
                    :to="{name:'help.detail',params:{id:child.id}}">
          {{child.docName}}</MenuItem>
        </Submenu>

      </Menu>
    </Card>
  </Affix>
</template>

<script>
import { getDocumentCate, getDocument } from '@/api/packing'
export default {
  data () {
    return {
      cateList: [],
      activeName: '',
      openNames: [],
      loading: 0
    }
  },
  watch: {
    $route: {
      deep: true,
      handler: function () {
        const { id } = this.$route.params
        if (id) this.activeName = id
      }
    }
  },
  async created () {
    const { id } = this.$route.params
    if (id) this.activeName = id
    await this.getData()
  },
  methods: {
    async getData () {
      return getDocumentCate({ pageSize: 100, pageNum: 1 }).then(res => {
        if (res.code === 0) {
          res.rows.forEach(item => {
            this.openNames.push(item.docName)
          })
          this.cateList = res.rows
          this.cateList.forEach((item, index) => {
            getDocument({ parentId: item.id, pageSize: 100, pageNum: 1 }).then(ret => {
              if (ret.code === 0) {
                this.loading++
                this.$set(this.cateList[index], 'child', ret.rows)
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-menu-vertical.ivu-menu-light:after {
    width: 0;
}
</style>
